<template>
    <Dropdown
        class="dropdown-plain"
        :show-check-box="true"
        no-all-controls
        :tags="false"
        plain
        selectionClass="text-md text-gray-600 font-normal"
        v-bind="$attrs"
        v-on="$listeners"
        custom-styles
    />
</template>

<script>
import Dropdown from "@shared/components/dropdown-base";

export default {
    name: 'DropdownPlain',
    components: {
        Dropdown,
    },
}
</script>

<style lang="scss" scoped>
.dropdown-plain {
    ::v-deep {
        .multiselect {
            @apply rounded-lg;
            background: none;
            &--disabled {
                background: none;
            }
            &--active {
                background-color: #ffffff;
            }
        }
    }
}
</style>