<template>
    <div>
       
        <div v-if="blockDetails && showInnerCascaded " class="bg-gray-200 py-3.5 px-3 text-gray-800 flex justify-between items-center border-primary border rounded-t-lg">
            <div class="flex items-center gap-10">
                <h2 class="labeled-fields font-normal text-sm text-primary flex items-stretch gap-2">
                    <div class=""  v-tippy :content="fetchLabel(blockDetails.name)" >
                         Name: 
                         <input 
                            type="text" 
                            v-model="blockDetails.name" 
                            @input="handleInput"
                            :disabled="!editBlockDetails" 
                            :class="!blockDetails.name ? 'border-b border-dashed border-red-300' : 'border-b border-dashed border-gray-300'" 
                            class="bg-transparent w-36 border-0 outline-none p-1 text-md text-gray-600 focus:bg-gray-100 rounded-md overflow-ellipsis" 
                        />
                    </div>
                    
                    <div class=""  v-tippy :content="fetchLabel(blockDetails.label)" >
                        Label: <input v-model="blockDetails.label" @input="handleInput" :disabled="!editBlockDetails" :class="!blockDetails.label ? 'border-b border-dashed border-red-300' : 'border-b border-dashed border-gray-300'" type="text" class="bg-transparent w-36 border-0 outline-none p-1 text-md text-gray-600 focus:bg-gray-100 rounded-md overflow-ellipsis" />
                    </div>
                    <div class=""  v-tippy :content="fetchLabel(blockDetails.block_title)" >
                         Block Title: <input v-model="blockDetails.block_title" @input="handleInput" :disabled="!editBlockDetails" :class="!blockDetails.block_title ? 'border-b border-dashed border-red-300' : 'border-b border-dashed border-gray-300'" type="text" class="bg-transparent w-36 border-0 outline-none p-1 text-md text-gray-600 focus:bg-gray-100 rounded-md overflow-ellipsis" />
                    </div>
                    <div
                        class="flex items-center flex-wrap gap-2" 
                        v-tippy
                        :content="fetchLabel(blockDetails.entity_types.map(el => el.name).join(', '))"
                    >
                         Entity Type:
                        <DropdownPlain
                            class="multiselect self-stretch"
                            v-model="entityTypesModel" 
                            @input="handleInput"
                            multiple
                            placeholder="Select Entities"
                            beforeLabel="Entities"
                            :loading="isEntityTypesOptionsLoading"
                            :config="ENTITY_TYPES_DROPDOWN_CONFIG"
                            :options="entityTypeOptions"
                            :disabled="!editBlockDetails"
                            min-width="160px"
                            :classList="[
                                'bg-transparent w-36 border-0 outline-none p-1 text-md text-gray-600 focus:bg-gray-100 rounded-md overflow-ellipsis',
                                !blockDetails.entity_types.length ? 'border-b border-dashed border-red-300' : 'border-b border-dashed border-gray-300'
                            ]"
                        />
                    </div>
                    <!-- ({{ blockDetails.fields.length }}) -->
                </h2>
                <div class="flex gap-5 items-center pr-10">
                    <div class="flex items-center gap-2 text-sm">
                        Multi
                        <input :disabled="!editBlockDetails" :value="blockDetails.multi" v-model="blockDetails.multi" @input="handleInput" type="checkbox" class="checkbox checkbox-primary border-white bg-card-bg outline-non border-none"  @change="updateMaxValue"/>
                    </div>
                    <div class="flex items-center gap-2 text-sm">
                        Min
                        <input 
                            type="number" 
                            :value="blockDetails.min_count" 
                            :disabled="!editBlockDetails || !blockDetails.multi" 
                            min="1" 
                            class="w-10 h-6 text-gray-600 rounded-md text-center font-bold"
                            @input="handleMinMaxCountChange({ key: 'min_count', $event })"
                        />
                    </div>
                    <div class="flex items-center gap-2 text-sm">
                        Max
                        <input 
                            type="number" 
                            :value="blockDetails.max_count" 
                            :min="blockDetails.min_count || 1" 
                            :disabled="!editBlockDetails || !blockDetails.multi" 
                            class="w-10 h-6 text-gray-600 rounded-md text-center font-bold" 
                            @input="handleMinMaxCountChange({ key: 'max_count', $event })"
                        />
                    </div>
                    <Button v-if="editBlockDetails" text="save" type="primary" class="btn-xs" @click="updateBlockDetails" :disabled="this.$route.params.action === 'view'"/>
                    <div>
                        <div v-if="this.$route.params.action !== 'view'" v-allow="`block.edit`"
                            class="h-8 w-8 flex items-center justify-center cursor-pointer rounded-half hover:bg-dvbrandcolor hover:text-white-text transition-all duration-150"
                            :class="editBlockDetails ? 'bg-dvbrandcolor text-white-text' : 'bg-gray-300'"
                            @click="switchEditMode">
                            <font-awesome-icon icon="edit" class="text-sm" />
                        </div>
                        <div v-else>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex space-x-4 items-center pr-3" >
                <button :disabled="this.$route.params.action == 'view'" v-if="checkPermission('field.read') && checkPermission('block.edit') " @click="showModalBlock" class="outline-none float-right text-primary hover:text-primary-focus" v-tippy content="Add Field">
                    <AddFieldIcon v-if="this.$route.params.action !== 'view'" class="h-5 w-5 cursor-pointer fill-current" v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }"/>
                </button>
                <font-awesome-icon  v-if="this.$route.params.action !== 'view'" v-allow="'block.delete'" v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" icon="trash" @click="$emit('deleteBlock', blockDetails)" class="cursor-pointer text-primary hover:text-primary-focus text-lg" v-tippy content="Delete Block" />
            </div>
        </div>
        <div v-if="blockDetails && showInnerCascaded " class=" transition-all duration-500 p-4 border-solid border-t-0 flex flex-col gap-3 bg-gray-100 rounded-b-lg">
            
            <p v-if="blockDetails && !blockDetails.fields.length" class="flex items-center justify-center text-gray-700 my-10">Add Fields to block</p>
            <div v-else class="overflow-x-auto scroll-bar pb-4">
            <div class="w-max min-w-full gap-2 flex flex-col">
                <transition>
                    <div class="pl-14 w-full flex">
                        <RolesHeader class="role-panel ml-auto mr-28 pr-3" :changeUi="true" />
                    </div>
                </transition>
                <Draggable class="list-group" tag="ul" :disabled="this.$route.params.action === 'view'" v-model="blockDetails.fields" v-bind="dragOptions" @change="$emit('hanldeFieldReorder', $event)" @start="drag = true" @end="drag = false">
                    <div class="flex flex-col gap-3 list-group-item mb-4" v-for="(field, index) in blockDetails.fields" :key="`${field.id} ${index}`">
                        <template v-if="field.is_cascaded">
                            <CascadedField
                                :isEditable="checkPermission('block.edit')"
                                @PermissionChange="PermissionChange($event)"
                                @change="change($event, field.id)"
                                @changeCaseCreation="changeCaseCreation($event)"
                                @removeFieldFromBlock="$emit('removeFieldFromBlock', $event)"
                                @updateFieldLabel="updateFieldLabel($event)"
                                @fieldNameClick="fieldNameClick($event)"
                                @showFieldSettingsModal="showFieldSettingsModal($event)"
                                :allReportsRoles="allReportsRoles"
                                :element="field"
                                :updateFieldPermissions="updateFieldPermissions"
                                :updateFieldAttributesMethod="change"
                                @changeblockVisibile="changeblockVisibile($event)"
                                :showVerticalGrip="showVerticalGrip"
                            />
                        </template>

                        <template v-else>
                            <FieldItem
                                :isEditable="checkPermission('block.edit')"
                                @PermissionChange="PermissionChange($event)"
                                @change="change($event, field.id)"
                                @changeCaseCreation="changeCaseCreation($event)"
                                @removeFieldFromBlock="$emit('removeFieldFromBlock', $event)"
                                @updateFieldLabel="updateFieldLabel($event)"
                                @fieldNameClick="fieldNameClick($event)"
                                @showGapValidationModal="showGapValidationModal(field)"
                                :allReportsRoles="allReportsRoles"
                                :field="field"
                                :updateFieldPermissions="updateFieldPermissions"
                                :updateFieldAttributesMethod="change"
                                @changeblockVisibile="changeblockVisibile($event)"
                                :showVerticalGrip="showVerticalGrip"
                            />
                        </template>
                    </div>
                </Draggable>
            </div>
            </div>
        </div>
        
        <div v-if="blockDetails && showInnerCascaded == false" class="transition-all duration-500 p-4 pb-0 border-solid border-t-0 flex flex-col gap-3 bg-gray-100 rounded-b-lg">
            <p v-if="blockDetails && !blockDetails.fields.length" class="flex items-center justify-center text-gray-700 my-10">Add Fields to block</p>
                <Draggable class="list-group" tag="ul" :draggable="false"  :disabled="this.$route.params.action === 'view'" v-model="blockDetails.fields" v-bind="dragOptions" @change="$emit('hanldeFieldReorder', $event)" @start="drag = true" @end="drag = false">
                    <div class="flex flex-col gap-3 list-group-item mb-4" v-for="(field, index) in blockDetails.fields" :key="`${field.id} ${index}`">
                        <template v-if="field.is_cascaded">
                            <CascadedField
                                :isEditable="checkPermission('block.edit')"
                                @PermissionChange="PermissionChange($event)"
                                @change="change($event, field.id)"
                                @changeCaseCreation="changeCaseCreation($event)"
                                @removeFieldFromBlock="$emit('removeFieldFromBlock', $event)"
                                @updateFieldLabel="updateFieldLabel($event)"
                                @fieldNameClick="fieldNameClick($event)"
                                @showFieldSettingsModal="showFieldSettingsModal($event)"
                                :allReportsRoles="allReportsRoles"
                                :element="field"
                                :updateFieldPermissions="updateFieldPermissions"
                                :updateFieldAttributesMethod="change"
                                @changeblockVisibile="changeblockVisibile($event)"
                                :showVerticalGrip="showVerticalGrip"
                            />
                        </template>

                        <template v-else>
                            <FieldItem
                                :isEditable="checkPermission('block.edit')"
                                @PermissionChange="PermissionChange($event)"
                                @change="change($event, field.id)"
                                @changeCaseCreation="changeCaseCreation($event)"
                                @removeFieldFromBlock="$emit('removeFieldFromBlock', $event)"
                                @updateFieldLabel="updateFieldLabel($event)"
                                @fieldNameClick="fieldNameClick($event)"
                                @showGapValidationModal="showGapValidationModal(field)"
                                :allReportsRoles="allReportsRoles"
                                :field="field"
                                :updateFieldPermissions="updateFieldPermissions"
                                :updateFieldAttributesMethod="change"
                                @changeblockVisibile="changeblockVisibile($event)"
                                :showVerticalGrip="showVerticalGrip"
                            />
                        </template>
                    </div>
                </Draggable>
        </div>
        <!-- TODO: REmove add block to section modal -->

        <AddFieldModal
            :blockDetails="blockDetails" 
            :open="showBlockAddModal" 
            :listType="listType" 
            :lists="filteredFieldList" 
            :loadingLists="fetchingFieldLists"
            @handleAddFieldToBlock="$emit('handleAddFieldToBlock', $event)" 
            @handleFilterResult="handleFilterResult($event)" 
            @close="handleCloseModal"
        />
        <FieldSettingsModal ref="fieldSettingsModal" 
            :data="activeFieldSettings" 
            :getDependentsMethod="getDependents" 
            :getOptionsMethod="getOptions" 
            :roles="allReportsRoles"
            :updateDependentFieldMethod="updateDependentsFieldLabel" 
            :changeCaseCreationMethod="changeCaseCreation"
            @closed="showFieldSettingsModal(null)"
            :changeblockVisibileMethod="changeblockVisibile"
            :queryData="this.$route.params"   />
        <GapValidationModal isBlockGap ref="gapValidationModal" :data="activeGapValidation" @closed="showGapValidationModal(null)" :updateFieldMethod="updateFieldMethod" />
        <!-- End  -->

        <modal-confirm 
            title="Are you sure?" 
            message="Unsaved data will be lost. Please confirm to continue without saving."
            ref="confirm-popup"> 
        </modal-confirm>
    </div>
</template>
<script>
// import Button from "@/components/button";
import AddFieldIcon from "@/assets/icons/check-admin/add-field.svg";
import Draggable from "vuedraggable";
import FieldItem from "../components/FieldItem.vue";
import AddFieldModal from "./addBlockAndFieldModal.vue";
import Button from "@shared/components/button";
import axios from "@/axios";
import CascadedField from "./cascadedFieldItem.vue";
import FieldSettingsModal from "@shared/components/modals/field-settings-modal.vue";
import RolesHeader from "@/pages/check-admin/check-dragable-section/components/roles-header";
// import { fetchReportVisibility } from "@/modules/field-editor/services.js";
import GapValidationModal from "@shared/components/modals/gap-validation-modal.vue";
import { checkPermission ,fetchLabel} from "@shared/utils/functions"
import DropdownPlain from "@shared/components/dropdown-plain";
import { ENTITY_TYPES_DROPDOWN_CONFIG } from "@shared/utils/constants";
import { mapActions, mapState } from 'vuex';
const modalConfirm = () => import("@/components/modal-confirm");


export default {
    name: "add-block",
    components: {
        // Button,
        AddFieldIcon,
        Draggable,
        FieldItem,
        AddFieldModal,
        Button,
        CascadedField,
        FieldSettingsModal,
        RolesHeader,
        GapValidationModal,
        DropdownPlain,
        modalConfirm
    },
    display: "Transitions",
    order: 7,
    props: {
        loadingState: {
            type: Object,
            default: () => {},
        },
        blockFields: {
            type: Array,
            default: () => [],
        },
        blockDetails: {
            type: Object,
            default: () => {},
        },
        showInnerCascaded:{
          type: Boolean
        },
        allReportsRoles: {
            type: Array,
            default: () => [],
        },
        isGlobalLoader: {
            type: Object,
        },
        handleControlGlobalLoader: {
            type: Function,
        },
        showVerticalGrip:{
            type: Boolean,
            default:true
        },
        openCascadedOption:{
            type: Boolean,
        },
    },
    async mounted() {
        this.tenantId = this.$store.getters.getTenantId;
        this.fetchEntityTypeOptions();
    },
    data() {
        return {
            // remove Dummy Data
            ENTITY_TYPES_DROPDOWN_CONFIG,
            tenantId: null,
            showBlockAddModal: false,
            editBlockDetails: false,
            listType: {
                type: "field",
                listModalTitle: "Add Field ",
                permissionModalTitle: "Field Settings",
            },
            fieldLists: [],
            fetchingFieldLists: false,
            filterFiledValue: "",
            dependentsFields: [],
            activeFieldSettings: {
                field: null,
            },
            oldFieldName: "",
            activeGapValidation: {
                field: null,
                section: null,
            },
            isEntityTypesOptionsLoading: false,
            fieldTypeFilter: [],
        };
    },
    computed: {
        ...mapState({
            entityTypeOptions: 'entityTypeList',
            isDirty: state => state.blocksAdmin.isDirty
        }),
        entityTypesModel: {
            get() {
                return this.blockDetails.entity_types;
            },
            set(val) {
                if (this.blockDetails?.fields?.length) {
                    this.$toast.error("Cannot update block's entity type as it associated with fields");
                } else if (!val.length) {
                    this.$toast.error("At least one entity type required.");
                } else {
                    this.blockDetails.entity_types = val;
                }
            },
        },
        dragOptions() {
            return {
                animation: 200,
                group: "block",
                disabled: false,
                ghostClass: "ghost",
            };
        },
        filteredFieldList() {
            if (this.filterFiledValue) {
                return this.fieldLists.filter((el) => el.name?.toLowerCase().includes(this.filterFiledValue?.toLowerCase()));
            } else {
                return this.fieldLists;
            }
        },
    },
    methods: {
        ...mapActions([
            'fetchEntityTypeList',
            'handleBlockDataChange'
        ]),
        handleMinMaxCountChange ({ key, $event }) {
            const value =  $event.target.value;
            if (value <= 0) {
                this.blockDetails[key] = 1;
                this.$forceUpdate()
            }
            this.handleInput(this.blockDetails)
        },
        handleInput () {
            this.handleBlockDataChange(this.blockDetails)
        },
        checkPermission,
        removeFieldFromBlock(event){
            this.$emit('removeFieldFromBlock', event)

        },
        updateMaxValue() {
            if (!this.blockDetails.multi) {
              this.blockDetails.min_count = 1;
              this.blockDetails.max_count = 1;
            }
        },
        fetchLabel,
        drag(e) {
            console.log(e, "drag");
        },
        fieldNameClick(field) {
            this.oldFieldName = field.label;
        },
        async updateFieldLabel(field) {
            if (this.oldFieldName !== field.label) {
                try {
                    let url = `/blocks/${this.blockDetails.id}/field/${field.id}`;
                    let payload = {
                        label: field.label,
                    };
                    let { data } = await axios.put(url, payload);
                    this.$toast.success(data.message || "Field updated");
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to update field ");
                }
            }
        },
        async updateFieldMethod(dt, { id, valid_gap, history }) {
            try {
                let url = `/blocks/${this.blockDetails.id}/field/${id}/attributes`;
                let payload = {
                    valid_gap: valid_gap,
                    gap_history: history,
                };
                let { data } = await axios.put(url, payload);
                if (data) {
                    this.$toast.success(data.message || "Gap updated");
                    let field_list = this.blockDetails.fields;
                    this.blockDetails.fields = field_list.map((fld) => (id === fld.id ? { ...fld, valid_gap, history } : fld));
                    this.$refs.gapValidationModal.closeModal();
                }
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to update field ");
            }
        },
        PermissionChange(role) {
            if (role.role_mandatory) {
                role.role_read = true;
                role.role_write = true;
                role.role_visible = true;
                role.select_all = true;
            } else if (role.role_write) {
                role.role_read = true;
                role.role_visible = true;
                role.select_all = false;
            } else if (role.role_read) {
                role.role_visible = true;
                role.select_all = false;
            }
        },

        async updateFieldPermissions(event, role, field_id, currentField) {
            // debugger //eslint-disable-line no-debugger
            this.handleControlGlobalLoader(true, 'Updating Field attributes...')
            try {
                let field_list = this.blockDetails.fields;
                let fieldIndex = field_list.findIndex((fld) => field_id === fld.id);
                let permissions = field_list[fieldIndex].roles.map((perm) => (perm.role_id === role.role_id ? role : perm));
                let url = `/blocks/${this.blockDetails.id}/field/${field_id}/attributes`;
                let payload = {
                    permissions: permissions,
                };
                let { data } = await axios.put(url, payload);
                if (data) {
                    this.$toast.success(data.message || "Field Permissions updated by");
                    this.blockDetails.fields[fieldIndex].roles = permissions;
                }
            } catch (error) {
                let match = currentField?.roles.find(el => el.role_id == event.role.role_id) 
                Object.assign(match, event.backup);
                this.handleControlGlobalLoader(false)
                this.$toast.error(error.response.data.detail || "Failed to update field ");
            }
            this.handleControlGlobalLoader(false)
        },

        async change(event, field_id) {
            this.handleControlGlobalLoader(true, 'Updating Field attributes...')
            try {
                let field_list = this.blockDetails.fields;
                let fieldIndex = field_list.findIndex((fld) => field_id === fld.id);

                let url = `/blocks/${this.blockDetails.id}/field/${field_id}/attributes`;
                let payload = {
                    report_visibility: event.id,
                };
                let { data } = await axios.put(url, payload);
                if (data) {
                    this.$toast.success(data.message || "Field updated");
                    this.blockDetails.fields[fieldIndex].report_visibility = event.id;
                }
            } catch (error) {
                this.handleControlGlobalLoader(false)
                this.$toast.error(error.response.data.detail || "Failed to update field ");
            }
            this.handleControlGlobalLoader(false)
        },
        beforeChangeDetails() {
            if (!this.isDirty) {
                return true
            } else {
                return new Promise((res) => {
                    this.$refs["confirm-popup"].show({
                        title: "Are you sure?",
                        message: "Unsaved data will be lost. Please confirm to continue without saving.",
                    }).then(choice => {
                        if (choice) {
                            this.editBlockDetails = false;
                            this.$emit('fetchBlockAdminDetails', () => res(choice));
                        } else res(choice);
                    });
                })
            }
        },
        async showModalBlock() {
            const canAddDetails = await this.beforeChangeDetails();
            if (!canAddDetails) return;
            const payload = {
                entity_type: this.blockDetails.entity_types.map((el) => el.id),
            }

            this.showBlockAddModal = true;
            this.filterFiledValue = "";
            this.fetchingFieldLists = true;
            this.fieldLists = await this.fetchFieldsList(payload);
            this.fetchingFieldLists = false;
        },
        async fetchFieldsList(payload) {
            let params = {
                tenant_id: this.tenantId,
                req_offset: 0,
                req_limit: 1,
                strict_entity_type_filter: true,
                ...payload,
            };
            let url = `/fields/${this.tenantId}/all`;
            let { data } = await axios.get(url, { 
                 params,
                 paramsSerializer: { indexes: null } 
             });
            return data.fields;
        },
        handleCloseModal() {
            this.showBlockAddModal = false;
        },
        switchEditMode() {
            if(this.$route.params.action !== 'view'){
                this.editBlockDetails = !this.editBlockDetails;
            }
        },
        handleFilterResult(event) {
            this.filterFiledValue = event;
        },
        updateBlockDetails() {
            if (this.blockDetails.multi && (parseInt(this.blockDetails.min_count, 10) > parseInt(this.blockDetails.max_count, 10))) {
                this.$toast.error("Max should be greater than or equal to min");
                return;
            } else if (!this.blockDetails.name?.trim()) {
                this.$toast.error("Name is required");
                return;
            } else if (!this.blockDetails.label?.trim()) {
                this.$toast.error("Label is required");
                return;
            } else {
                this.$emit("updateBlockDetails", this.blockDetails);
                this.switchEditMode();
            }
        },
        async showFieldSettingsModal(element = null) {
            if (!element) {
                this.activeFieldSettings = {
                    field: null,
                };
                return;
            }
            element.roles = element?.roles.map((role) => ({ ...role, select_all: role.role_mandatory && role.role_read && role.role_visible && role.role_write }));
            this.activeFieldSettings = {
                field: element,

                is_cascaded: element.is_cascaded,
            };
            // this.allReportsRoles = await fetchReportVisibility();
            this.$refs.fieldSettingsModal.showModal();
        },
        async getDependents(field_id, option_value) {
            const result = await axios.get(`/blocks/${this.blockDetails.id}/field/${field_id}/options/${option_value}`);
            // let formated = data.dependents.map(el => ({...el, field_label:el.label}))
            // const new_result = {
            //     data:{
            //         dependents:formated
            //     }
            // }
            return result;
        },

        async getOptions(field_id) {
            const { data } = await axios.get(`/blocks/${this.blockDetails.id}/field/${field_id}/options`);
            let nw_result = {
                data: {
                    field_options: data.data,
                },
            };
            return nw_result;
        },
        changeCaseCreation(event) {
            this.$emit("changeCaseCreation", event);
        },
        changeblockVisibile(event) {
            this.$emit("changeblockVisibile", event);
        },
        showGapValidationModal(data) {
            if (this.$route.params.action === 'view'){
                return 
            }
            if (data) {
                this.activeGapValidation.field = data;
                this.$refs.gapValidationModal.showModal();
            } else {
                this.activeGapValidation.field = null;
                this.$refs.gapValidationModal.closeModal();
            }
        },
        // async updateFieldLabel(sectionData, checkData, silent = false) {
        // }
        async updateDependentsFieldLabel(field) {
            try {
                // let field_list = this.blockDetails.fields;
                // let fieldIndex = field_list.findIndex(fld => field.id === fld.id);
                // let permissions = field_list[fieldIndex].roles.map(perm => (
                //     perm.role_id === role.role_id ? role : perm
                // ));

                let url = `/blocks/${this.blockDetails.id}/field/${field.id}/attributes`;
                let payload = {
                    permissions: field.permissions,
                    report_visibility: field.report_visibility
                };
                let { data } = await axios.put(url, payload);
                if (data) {
                    // this.$emit("fetchBlockAdminDetails");
                    this.$toast.success(data.message || "Field Permissions updated");
                    // this.blockDetails.fields[fieldIndex].roles = permissions;
                }
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to update field ");
            }
        },
        async fetchEntityTypeOptions() {
            this.isEntityTypesOptionsLoading = true;
            if (!this.entityTypeOptions.length) {
                await this.fetchEntityTypeList();
            }
            this.isEntityTypesOptionsLoading = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}
.labeled-fields {
    .multiselect {
        display: inline-block;
    }
}
</style>
