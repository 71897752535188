<template>
<div>
  <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                <!-- Heroicon name: outline/exclamation-triangle -->
                <svg class="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:v="https://vecta.io/nano"><path d="M474.045 173.813a8 8 0 0 0-5.123 10.088c7.571 23.199 11.411 47.457 11.411 72.1 0 62.014-24.149 120.315-68 164.166s-102.153 68-164.167 68-120.316-24.149-164.167-68S16 318.014 16 256 40.149 135.684 84 91.833s102.153-68 164.167-68c32.889 0 64.668 6.734 94.455 20.017 28.781 12.834 54.287 31.108 75.81 54.315a8 8 0 0 0 11.306.425c3.24-3.004 3.43-8.065.426-11.306-23-24.799-50.26-44.328-81.024-58.047-31.853-14.202-65.824-21.404-100.973-21.404-66.288 0-128.608 25.813-175.48 72.687S0 189.712 0 256s25.814 128.607 72.687 175.479 109.192 72.687 175.48 72.687 128.608-25.813 175.48-72.687S496.334 322.287 496.334 256c0-26.332-4.105-52.26-12.201-77.064-1.371-4.2-5.888-6.491-10.088-5.123zm30.924-90.551c-4.532-4.538-10.563-7.037-16.98-7.037s-12.448 2.499-16.978 7.034l-7.161 7.161a8 8 0 0 0 0 11.313c3.124 3.123 8.19 3.124 11.314-.001l7.164-7.164c1.51-1.512 3.52-2.344 5.66-2.344s4.15.832 5.664 2.348a7.96 7.96 0 0 1 2.348 5.663c0 2.139-.834 4.149-2.348 5.663L217.802 381.75c-1.51 1.512-3.52 2.344-5.66 2.344s-4.15-.832-5.664-2.348L98.747 274.015c-1.514-1.514-2.348-3.524-2.348-5.663a7.96 7.96 0 0 1 2.351-5.667c1.51-1.512 3.52-2.344 5.66-2.344s4.15.832 5.664 2.348l96.411 96.411a8 8 0 0 0 11.314 0l234.849-234.849a8 8 0 0 0 0-11.314c-3.124-3.123-8.189-3.123-11.313 0L212.142 342.129l-90.75-90.751a23.84 23.84 0 0 0-16.98-7.037c-6.417 0-12.448 2.499-16.978 7.034a23.85 23.85 0 0 0-7.034 16.977c0 6.412 2.498 12.441 7.034 16.978l107.728 107.728c4.532 4.538 10.563 7.037 16.98 7.037s12.448-2.499 16.977-7.033l275.847-275.848A23.85 23.85 0 0 0 512 100.236c0-6.413-2.498-12.442-7.031-16.974z"/></svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <div class="mt-2">
                  <p class="text-sm text-gray-500">{{message}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>

export default {
  name:"idcheck-confirmation",
  data(){
    return {
      message: 'Thank you for completing your online identity verification check. Please continue with your form or connect your administrator to proceed further.',
    }
  },
}
</script>
